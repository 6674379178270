/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICheckInHost } from './interfaces';

export const initialState: ICheckInHost = {
	whatsappMe: {
		loading: 'idle'
	},
	whatsappConnect: {
		loading: 'idle'
	},
	whatsappDisconnect: {
		loading: 'idle'
	},
	whatsappMessage: {
		loading: 'idle'
	},
	templates: {
		loading: 'idle'
	},
	whatsappWebTemplates: {
		loading: 'idle'
	},
	ttlockToken: {
		loading: 'idle'
	},
	ttlockList: {
		loading: 'idle'
	},
	fieldMapping: {
		loading: 'idle'
	},
	upsellingProducts: {
		loading: 'idle'
	},
	paymentHold: {
		loading: 'idle'
	},
	intentPaymentHold: {
		loading: 'idle'
	},
	upsellingPurchaseIntent: {
		loading: 'idle'
	},
	policyPayment: {
		loading: 'idle'
	},
	flexipassList: {
		loading: 'idle'
	},
	product: {
		loading: 'idle'
	},
	yaleConnectList: {
		loading: 'idle'
	},
	subscriptionPayment: {
		loading: 'idle'
	},
	upsellingPayment: {
		loading: 'idle'
	},
	upsellingBalance: {
		loading: 'idle'
	},
	upsellingBankAccounts: {
		loading: 'idle'
	},
	yaleConnectMapping: {
		loading: 'idle'
	}
};

export const rulesActionsLabel: any = {
	'create-reservation': 'Se envía al momento de crear la reserva',
	'day-hotel-access-info': 'Se envía el día de la llegada'
};

export const whatsAppTemplateTitleType: any = {
	REQUEST_CHECK_IN: 'Solicitar registro',
	ACCESS_INSTRUCTIONS: 'Instrucciones de acceso',
	CHECK_IN_ENABLED: 'Check-in habilitado',
	request_check_out_v2: 'Instrucciones para el check-out',
	early_check_in_bonus_v1: 'Bono para Early-check-in (entrada temprana)'
};

export const templateTitleType: any = {
	REQUEST_CHECK_IN: 'Solicitud del check-in',
	CHECK_IN_ENABLED: 'Check-in habilitado',
	REQUEST_CHECK_OUT: 'Recomendaciones para el check-out',
	EARLY_CHECK_IN_BONUS: 'Bono para Early-check-in (entrada temprana)'
};
