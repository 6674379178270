import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

type Block = {
	id: string;
	type: string;
	has_children?: boolean;
	children?: Block[];
	[key: string]: any;
};

interface NotionRendererProps {
	blocks: Block[];
}

export const NotionRenderer: React.FC<NotionRendererProps> = ({ blocks }) => {
	const renderBlock = (block: Block) => {
		const { type, id, has_children, children } = block;

		const renderRichText = (richTextArray: any[]) =>
			richTextArray.map((text: any, index: number) => {
				const { content, link, href } = text.text;
				const annotationsStyle = getAnnotationsStyle(text.annotations);

				if (content === '\u200b') {
					return (
						<>
							<br key={id} />
							<br key={id + '-1'} />
						</>
					);
				}

				if (link) {
					return (
						<Link
							key={index}
							href={link.url}
							target="_blank"
							rel="noopener noreferrer"
							style={annotationsStyle}
						>
							{content}
						</Link>
					);
				}

				return (
					<span key={index} id={id} style={annotationsStyle}>
						{content}
					</span>
				);
			});

		switch (type) {
			case 'paragraph':
				if (!block['paragraph']?.rich_text?.length) {
					return (
						<>
							<br key={id} />
							<br key={id + '-1'} />
						</>
					);
				}
				return (
					<Text key={id}>
						{renderRichText(block['paragraph']?.rich_text)}
					</Text>
				);

			case 'heading_1':
				return (
					<Heading1Styled key={id}>
						{renderRichText(block['heading_1']?.rich_text)}
					</Heading1Styled>
				);

			case 'heading_2':
				return (
					<Heading2Styled key={id}>
						{renderRichText(block['heading_2']?.rich_text)}
					</Heading2Styled>
				);

			case 'heading_3':
				return (
					<Heading3Styled key={id}>
						{renderRichText(block['heading_3']?.rich_text)}
					</Heading3Styled>
				);

			case 'quote':
				return (
					<blockquote key={id}>
						{renderRichText(block['quote']?.rich_text)}
					</blockquote>
				);

			case 'callout':
				return (
					<div
						key={id}
						style={{ border: '1px solid #ddd', padding: '10px' }}
					>
						{renderRichText(block['callout']?.rich_text)}
					</div>
				);

			case 'code':
				return (
					<pre key={id}>
						<code>{renderRichText(block['code']?.rich_text)}</code>
					</pre>
				);

			case 'image':
				return (
					<img
						key={id}
						src={block['image']?.file?.url || ''}
						alt={block['image']?.caption?.[0]?.plain_text || ''}
					/>
				);

			case 'video':
				return (
					<video
						key={id}
						controls
						src={block['video']?.file?.url || ''}
					/>
				);

			case 'table':
				return (
					<table
						key={id}
						style={{
							borderCollapse: 'collapse',
							width: 'fit-content',
							marginBlock: '10px'
						}}
					>
						<tbody>
							{children?.map(
								(rowBlock: any, rowIndex: number) => {
									const isHeader =
										rowIndex === 0 &&
										block['table']?.has_column_header;
									const RowTag = isHeader ? 'th' : 'td';

									return (
										<tr key={rowBlock.id}>
											{rowBlock.table_row.cells.map(
												(
													cell: any[],
													cellIndex: number
												) => (
													<RowTag
														key={cellIndex}
														style={{
															border: '1px solid #ddd',
															padding: '8px',
															textAlign: isHeader
																? 'center'
																: 'left',
															fontWeight: isHeader
																? 'bold'
																: 'normal'
														}}
													>
														{cell.map((text: any) =>
															renderRichText([
																text
															])
														)}
													</RowTag>
												)
											)}
										</tr>
									);
								}
							)}
						</tbody>
					</table>
				);

			case 'table_of_contents':
				return renderTableOfContents(blocks);
			//return <div key={id}>[Table of Contents Placeholder]</div>;

			case 'child_page':
				return <></>;

			default:
				return (
					/*for test*/
					// <div key={id}>[Unsupported Block Type-{block.type}]</div>
					<></>
				);
		}
	};

	const renderList = (
		blocks: Block[],
		listType: 'bulleted_list_item' | 'numbered_list_item',
		isChild = false
	) => {
		const ListTag = listType === 'numbered_list_item' ? 'ol' : 'ul';

		return (
			<ListTag
				style={{
					listStylePosition: 'inside',
					paddingLeft: isChild ? '2rem' : '0.5rem',
					marginLeft: '0',
					marginBottom: '2px'
				}}
			>
				{blocks.map((block) => (
					<li key={block.id} style={{ marginBottom: 4 }}>
						{block[listType]?.rich_text?.map(
							(text: any, index: number) => (
								<>
									<Text
										key={index}
										style={getAnnotationsStyle(
											text.annotations
										)}
									>
										{text.text.content}
									</Text>
								</>
							)
						)}
						{block.has_children && (
							<>
								<br />
								<NotionRenderer blocks={block.children || []} />
							</>
						)}
					</li>
				))}
			</ListTag>
		);
	};

	// Agrupamos los bloques de lista numerada y viñetas
	const groupBlocks = () => {
		const grouped: JSX.Element[] = [];
		let tempList: Block[] = [];
		let currentListType:
			| 'bulleted_list_item'
			| 'numbered_list_item'
			| null = null;

		blocks.forEach((block) => {
			if (
				block.type === 'bulleted_list_item' ||
				block.type === 'numbered_list_item'
			) {
				if (currentListType === block.type) {
					tempList.push(block);
				} else {
					if (tempList.length > 0) {
						grouped.push(renderList(tempList, currentListType!));
					}
					tempList = [block];
					currentListType = block.type;
				}
			} else {
				if (tempList.length > 0) {
					grouped.push(renderList(tempList, currentListType!));
					tempList = [];
					currentListType = null;
				}
				grouped.push(renderBlock(block));
			}
		});

		// Renderizar la lista pendiente al final
		if (tempList.length > 0) {
			grouped.push(renderList(tempList, currentListType!, true));
		}

		return grouped;
	};

	const renderTableOfContents = (blocks: Block[]) => {
		const headers = blocks.filter((block) =>
			['heading_1', 'heading_2', 'heading_3'].includes(block.type)
		);

		return (
			<nav key="table_of_contents">
				<ol>
					{headers.map((header) => {
						const level = parseInt(header.type.split('_')[1], 10); // Obtener nivel (1, 2, 3)
						const text = header[header.type]?.rich_text
							?.map((text: any) => text.text.content)
							.join(' ');

						const handleClick = (
							e: React.MouseEvent<HTMLAnchorElement>
						) => {
							e.preventDefault(); // Evita el comportamiento predeterminado del enlace
							const element = document.getElementById(header.id);

							if (element) {
								const elementPosition =
									element.getBoundingClientRect().top +
									window.scrollY; // Posición del elemento relativa a la ventana
								const offsetPosition = elementPosition - 200; // Aplica el margen superior de 200px

								window.scrollTo({
									top: offsetPosition,
									behavior: 'smooth'
								});
							}
						};

						return (
							<li
								key={header.id}
								style={{
									marginLeft: `${(level - 1) * 20}px`,
									listStyle: 'none'
								}}
							>
								<a
									onClick={handleClick}
									style={{ textDecoration: 'none' }}
								>
									{text}
								</a>
							</li>
						);
					})}
				</ol>
			</nav>
		);
	};

	return <>{groupBlocks()}</>;
};

const getAnnotationsStyle = (annotations: any) => ({
	fontWeight: annotations?.bold ? 'bold' : 'normal',
	fontFamily: annotations?.bold
		? 'InterRegularBold, AmikoRegularBold, sans-serif'
		: 'InterRegular, AmikoRegular, sans-serif',
	fontStyle: annotations?.italic ? 'italic' : 'normal',
	textDecoration: annotations?.strikethrough
		? 'line-through'
		: annotations?.underline
		? 'underline'
		: 'none',
	color: annotations?.color !== 'default' ? annotations.color : 'inherit'
});

export default NotionRenderer;

const Heading1Styled = styled(Typography.Text)`
	//display: flex;
	line-height: 1.3;
	font-weight: bold;
	font-family: 'InterRegularBold, AmikoRegularBold, sans-serif';
	font-size: 38px;
	text-align: start;
	color: #0d1d36;

	@media (max-width: 768px) {
		font-size: ${({ theme }) => theme.title.size.large * 1.3}px;
		margin-bottom: 0;
	}
`;

const Heading2Styled = styled(Typography.Text)`
	line-height: 1.3;

	text-align: start;

	font-size: 20px;
	font-family: 'InterRegularBold, AmikoRegularBold, sans-serif';
	font-weight: bold;
	font-size: 1.5em;
	line-height: 1.3;
	padding: 3px 2px;
	margin-top: 20px;

	@media (max-width: 768px) {
		font-size: ${({ theme }) => theme.title.size.small}px;
	}
`;

const Heading3Styled = styled(Typography.Text)`
	line-height: 1.3;

	text-align: start;

	font-size: 18px;
	font-family: 'InterRegularBold, AmikoRegularBold, sans-serif';
	font-weight: bold;
	font-size: 1.5em;
	line-height: 1.3;
	padding: 3px 2px;
	margin-top: 20px;
`;

const Text = styled(Typography.Text)`
	//line-height: 1.8;
	font-size: 17px;
	text-align: start;
	padding: 3px 2px;

	@media (max-width: 768px) {
		margin-bottom: 0;
	}
`;

const Link = styled.a`
	text-decoration: underline !important;
`;
