/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestCreate, RequestFindAll } from '../requests';
import { Fetch } from '../fetch';

import { ResponseFailure, ResponseSuccess } from '../responses';

export const RequestFindAllWhatsappTemplates = (
	params: RequestFindAll
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch(
		RequestFindAllWhatsappTemplates.name,
		'api/notifications/whatsapp/templates',
		params
	);

export const RequestPostWhatsappWebMessageUseTemplates = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch(
		'post',
		'api/notifications/whatsapp-web/message-use-templates',
		params
	);
