import { TpReservationData_AccessCodes } from '../third-party-reservations/interfaces';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type CurrentAppNameType = 'MAIN' | 'START';

export type AppNameType = {
	es: string;
	en: string;
};

type AppStatusType = 'live' | 'dev' | 'listed';
type AppIconType = 'icons-material' | 'icons-ant' | 'icons-hospy';

export type AppIcon = {
	type: AppIconType;
	name: string;
};
// TODO: utilizar 'AppData'
export type AppDataType = {
	_id: string;
	active: boolean;
	name: AppNameType;
	status: AppStatusType;
	icon: AppIcon;
	integrationOnly: boolean;
	partnerIntegrations: PartnerIntegration[];
	description: AppNameType;
	availableCountries: any[];
	knowledgeBaseUrl?: string;
	privacyPoliciesUrl?: string;
	termsAndConditionsUrl?: string;
};

// export type loadingType = 'idle' | 'pending' | 'succeeded' | 'failed';

export type Loading = 'idle' | 'pending' | 'succeeded' | 'failed';

export type AppName = {
	es: string;
	en: string;
};

export type AppStatus = 'live' | 'dev' | 'listed';

export interface IFee {
	start: number;
	end: number;
	value: number;
	byAccommodation: boolean;
	_id?: string;
}

export interface IPartnerDescription {
	description: string;
	id: string;
}

export type AppData = {
	type: 'front' | 'back';
	developers: any[];
	pricingModel: string;
	_id: string;
	active: boolean;
	name: AppName;
	status: AppStatus;
	icon: AppIcon;
	integrationOnly: boolean;
	partnerIntegrations: PartnerIntegration[];
	description: AppNameType;
	availableCountries: any[];
	availableLanguages: any[];
	knowledgeBaseUrl?: string;
	privacyPoliciesUrl?: string;
	termsAndConditionsUrl?: string;
	maintenance?: boolean;
	maintenanceMessage?: boolean;
	price: any;
	url: string;
	trialPeriod: number;
	transactionFees: Array<IFee>;
	commissionFees: Array<IFee>;
	monthlyFees: Array<IFee>;
	creditFees: boolean;
	transactionalApiFee?: number;
	monthlyApiFee?: number;
	annualDiscount?: number;
};

type PropertyType = 'hotel' | 'short rental';

export type PropertyBase = {
	_id: string;
	name: string;
	location: {
		city: { _id: string; name: string };
		country: { _id: string; name: string; iso: string };
		state: { _id: string; name: string; iso: string };
	};
	currency: string;
	timezone: string;
	phone?: string;
	type: string;
};

export type PropertyIntegrationBase = {
	_id: string;
	name: string;
	propertyPartner: {
		name: string;
		currencyCode?: string;
	};
	partnerIntegrationId?: string;
	extras?: any[];
	partnerIntegrations?: any[];
};

export type Property = {
	_id: string;
	type: PropertyType;
	apps: any[];
	name: string;
	currency?: string;
	rol: string;
	phone?: string;
	channel?: string;
	location: any;
	timezone: string;
	slug?: string;
	domain?: string;
	url?: string;
	isCloudbedsUser?: boolean;
};

type propertyPartner = {
	id?: string;
	name?: string;
	currencyCode?: string;
	vat?: string;
	link?: string;
	step?: string;
	extras?: any[];
};

export type PropertyIntegrations = {
	_id?: string;
	name?: string;
	propertyId: string;
	partnerIntegrationId: string;
	partnerIntegrationType: string;
	apps?: string[];
	appId: string | undefined;
	authMode: string;
	clientId?: string;
	clientSecret?: string;
	apiToken?: string;
	apiUsername?: string;
	apiPassword?: string;
	apiUrl?: string;
	accessToken?: string;
	accessTokenRevoked?: boolean;
	accessTokenExpirationDate?: Date;
	refreshToken?: string;
	propertyPartner?: propertyPartner;
	webhooks?: Webhook[];
	events?: Event[];
	active?: boolean;
	partnerIntegrations?: PartnerIntegration[];
	parentId?: string;
	createdAt?: string;
	extras?: any[];
	partnerAccountDataRequest?: any;
	partnerAccountDataResponse?: any;
};

export type Webhook = {
	object: string;
	action: string;
};

export type Event = {
	eventFrom: {
		slug: string;
	};
	eventTo: {
		slug: string;
	};
};

export type AppPartnerCredentials = {
	appId: string;
	clientId: string;
	clientSecret: string;
};

export type PartnerIntegration = {
	_id: string;
	name: string;
	type: string;
	authMode: string;
	ipRequired: boolean;
	appsPartnerCredentials?: AppPartnerCredentials[];
	authUrl: string;
	scope: string;
	accessTokenUrl: string;
	authUrlCallback: string;
	updateGuestUrl: string;
	requestTypeUpdateGuest: string;
	logo: string;
	icon: string;
	urlEndpointTest: string;
	requestTypeTest: string;
	description?: string;
	status?: string;
	rawDescription?: string;
	createdAt?: string;
	tooltip?: string; // TODO: agregado para el onboarding de check in hots + Cloudbeds, para poder mostrar un tooltip custom, "Debes conectar primero TRA & SIRE para conectar Check-in online"
};

export type FieldExtra = {
	_id?: string;
	slug: string;
	name: string;
	type: string;
	value?: string;
};
export type Field = {
	_id?: string;
	name?: AppName;
	externalId: string;
	externalSlug: string;
	externalName?: string;
	externalType?: string;
	internalSlug: string;
	internalId?: string;
	internalName?: string;
	internalType?: string;
	extras?: FieldExtra[];
	groupName?: string;
	disabled?: boolean;
};

export type FieldSync = {
	sync?: 'idle' | 'sync' | 'not-found';
} & Field;

export type FieldMapping = {
	_id?: string;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	apps: string[];
	fields: Field[];
	propertyIntegrationId?: string;
	propertyIntegrationIdTo?: string;
};

export enum PartnersNames {
	'CLOUDBEDS' = 'CLOUDBEDS'
}

export type InternalRoute = {
	path: string;
	redirect?: string;
	pathVariant?: string[];
	component?: any;
};

export enum TransactionInternalStatus {
	'PENDING' = 'pending',
	'PROCESSING' = 'processing',
	'REFUSED' = 'refused',
	'PROCESSED' = 'processed',
	'CANCELLED' = 'cancelled',
	'UNKNOWN' = 'unknown'
}

export const latamCountriesIso2 = [
	'CO',
	'MX',
	'PE',
	'DO',
	'AR',
	'EC',
	'CL',
	'CR',
	'PA',
	'BR',
	'NI',
	'PY',
	'BO',
	'PR',
	'HN',
	'GT',
	'BS',
	'SV',
	'JM',
	'CW',
	'AW',
	'TT',
	'UY'
];

export interface ILanguageItem {
	code: string;
	name: any;
	flag?: string;
}

export const availableLanguages: ILanguageItem[] = [
	{ code: 'es', name: { es: 'Español', pt: 'Espanhol', en: 'Spanish' } },
	{
		code: 'pt',
		name: { es: 'Portugués', pt: 'Português', en: 'Portuguese' }
	},
	{ code: 'en', name: { es: 'Inglés', pt: 'Inglês', en: 'English' } }
];

export const defaultLanguage = 'es';

export enum PARTNER {
	CLOUDBEDS = '6364387f9cc192f99879bbed',
	SIIGO = '63f9340af275a92dcc6464c2',
	AIRBNB = '63e132d5f9231c00f1494955',
	FACTURANTE = '64437ccf2e2331462bd60b61',
	FACTURAMA = '645e612191a8a044e09ed0df',
	POS = '647e5c9713b979591eb931b1',
	ALEGRA = '647779e4be8b667e2c50fd4c',
	HUBSPOT = '617c9f9e08a52c0001958a01',
	TTLOCK = '656a057f2782dfc6c68556b1',
	FLEXIPASS = '669040aaba07c2d375205752',
	STAYS = '640778fae6310e8ac1d55b7f',
	HOSTFULLY = '6697e71e1c03031d1bdfce76',
	COLPPY = '66b5521aebd87985d38f4b7f',
	YALE_CONNECT = '66ec586409418d460b904496'
}

export enum APPS {
	CHECKIN_HOST = '64e0085176019860a1a64bb3',
	SIRE_TRA = '63755983daa4cef95db5d226',
	PAYMENT_COLLECT = '635b5c83370446c2585b7818',
	POWERBI_CONNECT = '64fe38fea1aacce2434070f7',
	INVOICE_MANAGER = '6356a12f904a7a71a90056c6',
	SIIGO_CONNECT = '63703fd5b7a852979f10e0d1',
	EFACTURA_SAT = '64011389e2103240c429d662',
	EFACTURA_AFIP = '641cd190107120c7f31e47ab',
	EFACTURA_DIAN = '640113772dfdcaf3b9fe85bd',
	HUBSPOT_CONNECT = '64fe38bda1aacce2434070f6',
	SCAN_ID = '65e8c12a74bd646992ce8030'
}

export enum PLANS {
	BASIC = '673cf5042315a011eb698849',
	PRO = '673cf5ec2315a011eb69884b'
}

export const satFiscalRegimes = [
	{
		value: 601,
		label: '601 - General de Ley Personas Morales'
	},
	{
		value: 603,
		label: '603 - Personas Morales con Fines no Lucrativos'
	},
	{
		value: 605,
		label: '605 - Sueldos y Salarios e Ingresos Asimilados a Salarios'
	},
	{
		value: 606,
		label: '606 - Arrendamiento'
	},
	{
		value: 607,
		label: '607 - Régimen de Enajenación o Adquisición de Bienes'
	},
	{
		value: 608,
		label: '608 - Demás ingresos'
	},
	{
		value: 609,
		label: '609 - Consolidación'
	},
	{
		value: 610,
		label: '610 - Residentes en el Extranjero sin Establecimiento Permanente en México'
	},
	{
		value: 611,
		label: '611 - Ingresos por Dividendos (socios y accionistas)'
	},
	{
		value: 612,
		label: '612 - Personas Físicas con Actividades Empresariales y Profesionales'
	},
	{
		value: 614,
		label: '614 - Ingresos por intereses'
	},
	{
		value: 615,
		label: '615 - Régimen de los ingresos por obtención de premios'
	},
	{
		value: 616,
		label: '616 - Sin obligaciones fiscales'
	},
	{
		value: 620,
		label: '620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos'
	},
	{
		value: 621,
		label: '621 - Incorporación Fiscal'
	},
	{
		value: 622,
		label: '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'
	},
	{
		value: 623,
		label: '623 - Opcional para Grupos de Sociedades'
	},
	{
		value: 624,
		label: '624 - Coordinados'
	},
	{
		value: 625,
		label: '625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'
	},
	{
		value: 626,
		label: '626 - Régimen Simplificado de Confianza'
	},
	{
		value: 628,
		label: '628 - Hidrocarburos'
	},
	{
		value: 629,
		label: '629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales'
	},
	{
		value: 630,
		label: '630 - Enajenación de acciones en bolsa de valores'
	}
];
export interface IUserModel {
	_id?: string;
	name: string;
	email: string;
	properties?: Array<Property> | undefined;
}

export type Frequency = 'yearly' | 'monthly';
export type TypeDiscount = 'percent' | 'fixed';
export interface IDiscountValidation {
	code: string;
	appId: string;
	propertyId: string;
	frequency: Frequency;
}

export interface Discount {
	_id: string;
	amount: number;
	code: string;
	appIds: string[];
	startDate: Date;
	endDate: Date;
	frequency: Frequency;
	type: TypeDiscount;
	monthFree: number;
}

export interface IDiscount {
	code: string;
	frequency: string;
	type: string;
	amount: number;
}

export const partnerImages: any = {
	[PARTNER.CLOUDBEDS]:
		'https://cdn.hospy.co/images/partner-integrations/logos/cloudbeds-isotipo.svg',
	[PARTNER.AIRBNB]:
		'https://cdn.hospy.co/images/partner-integrations/logos/airbnb2.svg'
};

export type ReservationStatus = 'draft' | 'check-in' | 'check-out';

export type AddressReservation = {
	countryId: string;
	countryName: string;
	stateId?: string;
	stateName?: string;
	cityId?: string;
	cityName?: string;
	line?: string;
	countryCode?: string;
};

export type CountryDocument = {
	countryId: string;
	countryName: string;
};

export type GuestReservation = {
	_id?: string;
	id?: string;
	isMain: boolean;
	firstName: string;
	lastName: string;
	birthdate: Date;
	phone: string;
	email?: string;
	address?: string;
	documentTypeId: string;
	documentTypeName: string;
	documentNumber: string;
	photoDocumentFront: string | undefined;
	selfie: string | undefined;
	signedSelfie: string | undefined;
	photoDocumentBack: string | undefined;
	photoDocumentStamp: string | undefined;
	signedPhotoDocumentFront: string | undefined;
	signedPhotoDocumentBack: string | undefined;
	signedPhotoDocumentStamp: string | undefined;
	photoSign: string;
	reasonTrip: string;
	destiny?: AddressReservation;
	provenance?: AddressReservation;
	nationality: AddressReservation;
	totalGuest: number;
	createdAt?: Date;
	updatedAt?: Date;
	interests: string[];
	thirdPartyId?: string;
	state?: 'pending' | 'complete';
	status?: string;
	checkinCompletedAt?: Date;
	geolocation?: any;
	gender?: 'male' | 'female';
	selfieValidation?: boolean;
	countryDocument: CountryDocument;
};

export type Reservation_AccessCode_Log = {
	messageType: string;
	subject: string;
	bodyFormat: string;
	body: string;
};
export type Reservation_AccessCode = {
	_id?: string;
	partnerId?: string;
	partnerSlug?: string;
	rooms?: Reservation_Room[];
	lockId?: string;
	code: string;
	name: string;
	suffix?: string;
	thirdPartyId?: string;
	status: 'success' | 'failure';
	deletedAt?: Date;
	log?: Reservation_AccessCode_Log;
};

export type Reservation_Room = {
	id: string;
	name: string;
};

export type Reservation = {
	_id?: string;
	status: ReservationStatus;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	checkInDate?: Date;
	checkOutDate?: Date;
	adults: number;
	children: number;
	total: number;
	createdAt?: Date;
	updatedAt?: Date;
	thirdPartyId: string;
	thirdPartySourceId?: string;
	thirdPartyCustomId?: string;
	guests?: GuestReservation[];
	roomName?: string;
	totalGuests?: number;
	roomId?: string;
	statuses?: {
		tra: { status: string; _id: string };
		sire: { status: string; _id: string };
		sireIn: { status: string; _id: string };
		sireOut: { status: string; _id: string };
	};
	arrivalTime?: string;
	departureTime?: string;
	flightNumber?: string;
	departureFlightNumber?: string;
	currency?: string;
	extras?: any[];
	channelSlug?: string;
	channelName?: string;
	email?: string;
	phone?: string;
	rooms?: any[];
	feedback?: string;
	paymentPhoto?: string;
	signedPaymentPhoto?: string;
	minorPermissionImage?: string;
	signedMinorPermissionImage?: string;
	accessCodes: TpReservationData_AccessCodes[];
	enableAccessDate?: Date;
	holder?: string;
};

export const COLOMBIA_COUNTRY_ID = '63646413b194e6aba110e08b';
export const ARGENTINA_COUNTRY_ID = '63646411b194e6aba110455d';
export const VENEZUELA_COUNTRY_ID = '6364641db194e6aba1150777';
export const MEXICO_COUNTRY_ID = '63646416b194e6aba11267a2';
export const USA_COUNTRY_ID = '6364641cb194e6aba114690d';
export const CANADA_COUNTRY_ID = '63646412b194e6aba110c8c7';
export const PERU_COUNTRY_ID = '63646418b194e6aba112df1d';
export const ECUADOR_COUNTRY_ID = '63646413b194e6aba11107a3';
export const CHILE_COUNTRY_ID = '63646412b194e6aba110d315';
export const BOLIVIA_COUNTRY_ID = '63646412b194e6aba1108f5b';
export const BRAZIL_COUNTRY_ID = '63646412b194e6aba1109408';
export const URUGUAY_COUNTRY_ID = '6364641db194e6aba11504a2';
export const SURINAM_COUNTRY_ID = '6364641ab194e6aba1140678';
export const GUAYANA_COUNTRY_ID = '63646414b194e6aba1115911';
export const CUBA_COUNTRY_ID = '63646413b194e6aba110f282';
export const GUATEMALA_COUNTRY_ID = '63646414b194e6aba1119cc2';
export const PUERTO_RICO_COUNTRY_ID = '63646418b194e6aba1133a2a';
export const PORTUGAL_COUNTRY_ID = '63646418b194e6aba1132fa8';
export const PANAMA_COUNTRY_ID = '63646418b194e6aba112d7e6';
export const PARAGUAY_COUNTRY_ID = '63646418b194e6aba112ddce';
export const REPUBLICA_DOMINICANA_COUNTRY_ID = '63646413b194e6aba1110518';
export const EL_SALVADOR_COUNTRY_ID = '63646413b194e6aba1110a64';
export const COSTA_RICA_COUNTRY_ID = '63646413b194e6aba110eac1';
export const NICARAGUA_COUNTRY_ID = '63646417b194e6aba112c766';
export const HONDURAS_COUNTRY_ID = '63646415b194e6aba111a264';
export const ESPANA_COUNTRY_ID = '6364641ab194e6aba113c1bd';
export const FRANCIA_COUNTRY_ID = '63646413b194e6aba111128e';
