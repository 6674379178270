/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import { Icon } from '@hospy/hospy-ui';
import { AnimatePresence, motion } from 'framer-motion';
import styled, { css, useTheme } from 'styled-components';

export type AlertType = 'info' | 'danger' | 'success' | 'primary';

interface Props {
	type?: AlertType;
	label?: string;
	withoutIcon?: boolean;
	style?: React.CSSProperties;
	children?: React.ReactElement | React.ReactElement[];
	animate?: 'true' | 'false';
	border?: boolean;
	icon?: React.ReactElement;
	solid?: boolean;
}

export const HospyAlert = ({
	type,
	label,
	withoutIcon,
	children,
	animate,
	border,
	icon,
	...rest
}: Props) => {
	const theme: any = useTheme();
	const iconStyle = {
		fontSize: 30
	};

	const content = (
		<Container
			type={type || 'info'}
			border={border === undefined ? true : border}
			{...rest}
		>
			{!withoutIcon && (
				<>
					{type === 'info' && (
						<NotificationImportantIcon
							style={{
								...iconStyle,
								color: theme.colors.info.base
							}}
						/>
					)}
					{type === 'success' && (
						<CheckCircleFilled
							style={{
								...iconStyle,
								color: theme.colors.success.base
							}}
						/>
					)}
				</>
			)}
			{icon}
			{label}
			{children}
		</Container>
	);

	if (animate === 'true') {
		return (
			<AnimatePresence>
				{(label || children) && (
					<motion.div
						initial={{ opacity: 0, y: -10 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: -10 }}
					>
						{content}
					</motion.div>
				)}
			</AnimatePresence>
		);
	}

	return <>{content}</>;
};

interface DivProps {
	readonly type?: AlertType;
	readonly border?: boolean;
	readonly solid?: boolean;
}

const NotificationImportant = (props: any) => (
	<Icon material="notification_important" {...props} />
);
const NotificationImportantIcon = styled(NotificationImportant)`
	color: ${({ theme }) => theme.colors.primary.base};
	font-size: 30px;
`;

const Container = styled.div<DivProps>`
	display: flex;
	align-items: center;
	${({ border }) =>
		border &&
		css`
			border: 1px solid;
		`}
	${({ type }) =>
		type === 'primary' &&
		css`
			color: ${({ theme }) => theme.colors.primary.base};
			border-color: ${({ theme }) =>
				theme.colors.primary?.lighten?.['10%']};
			background-color: ${({ theme }) =>
				theme.colors.primary?.lighten?.['90%']};
		`}
    ${({ type }) =>
		type === 'info' &&
		css`
			color: ${({ theme }) => theme.colors.info.base};
			border-color: ${({ theme }) => theme.colors.info?.lighten?.['10%']};
			background-color: ${({ theme }) =>
				theme.colors.info?.lighten?.['90%']};
		`}
    ${({ type, solid }) =>
		type === 'danger' &&
		!solid &&
		css`
			color: ${({ theme }) => theme.colors.danger.base};
			border-color: ${({ theme }) =>
				theme.colors.danger.lighten?.['10%']};
			background-color: ${({ theme }) =>
				theme.colors.danger.lighten?.['90%']};
		`}
		${({ type, solid }) =>
		type === 'danger' &&
		solid &&
		css`
			color: #fff;
			border-color: ${({ theme }) => theme.colors.danger.base};
			background-color: ${({ theme }) => theme.colors.danger.base};
		`}
    ${({ type }) =>
		type === 'success' &&
		css`
			border-color: ${({ theme }) =>
				theme.colors.success.lighten?.['10%']};
			background-color: ${({ theme }) =>
				theme.colors.success.lighten?.['90%']};
		`}
    padding: 15px 20px;
	font-size: 15px;
	gap: 15px;
	& > span svg {
		font-size: 30px;
	}
`;

export default HospyAlert;
