import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../atoms/button/button';
import Modal, { ModalProps } from '../../template/modal/modal';

interface Props extends ModalProps {
	detail?: React.ReactNode;
	onConfirm: () => Promise<void>;
	danger?: boolean;
	textCenter?: boolean;
}
export const ConfirmationModal = ({
	open,
	onClose,
	title,
	detail,
	onConfirm,
	danger,
	textCenter,
	...rest
}: Props) => {
	const [loading, setLoading] = useState(false);

	return (
		<Modal open={open} onClose={onClose} size="small" {...rest}>
			<ModalWrapper>
				{typeof title === 'string' ? (
					<ModalTitle>{title}</ModalTitle>
				) : (
					title
				)}
				<ModalBody style={textCenter ? { textAlign: 'center' } : {}}>
					{detail}
				</ModalBody>
				<ModalFooter>
					<Button
						style={{ minWidth: 150 }}
						onClick={onClose}
						disabled={loading}
					>
						Cancelar
					</Button>
					<Button
						style={{ minWidth: 150 }}
						type="primary"
						onClick={async () => {
							setLoading(true);
							await onConfirm();
							setLoading(false);
						}}
						loading={loading}
						danger={danger || false}
					>
						Confirmar
					</Button>
				</ModalFooter>
			</ModalWrapper>
		</Modal>
	);
};
export default ConfirmationModal;

const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	/* gap: 15px; */
`;

const ModalTitle = styled.div`
	font-size: ${({ theme }) => theme.title.size.large}px;
	text-align: center;
	margin-top: 15px;
	margin-bottom: 0;
`;
const ModalBody = styled.div`
	font-size: ${({ theme }) => theme.text.size.large}px;
`;

const ModalFooter = styled.div`
	display: flex;
	justify-content: center;
	gap: 15px;
	margin-top: 30px;
`;
