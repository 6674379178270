import { TermsAndConditionsInitialState } from './interface';

export const termsAndConditionsInitialState: TermsAndConditionsInitialState = {
	termsAndCondition: undefined,
	termsAndConditions: {
		loading: 'idle',
		error: undefined
	},
	create: {
		loading: 'idle',
		error: undefined
	},
	update: {
		loading: 'idle',
		error: undefined
	},
	deleteTerms: {
		loading: 'idle',
		error: undefined
	}
};
