/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	getApps,
	getCjm,
	getCountryService,
	getDataProcessed,
	getPartners,
	getPropertySubscriptions,
	getReportCheckinData,
	getReportIMRData,
	getReportSatData,
	getReportSiigoData,
	getReportSireTraData,
	getResumeService,
	getSellByApplicationService
} from '@hospy/util-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

interface GetSellByApplicationProps {
	startDate: string;
	endDate: string;
}

export const getSellByApplication = createAsyncThunk(
	'analytics/getSellByApplication',
	async (
		{ startDate, endDate }: GetSellByApplicationProps,
		{ rejectWithValue }
	) => {
		try {
			const response = await getSellByApplicationService(
				startDate,
				endDate
			);
			if (response.statusCode !== 200)
				return rejectWithValue(response.message);
			else if (response.statusCode === 500)
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);

			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const analyticsGetApps = createAsyncThunk(
	'analytics/getApps',
	async (_, { rejectWithValue }) => {
		try {
			const response = await getApps(
				`filter={"status":"live"}&fields=["_id","name","partnerIntegrations"]`
			);

			if (response.statusCode !== 200) {
				return rejectWithValue(response.message);
			} else if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}

			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const analyticsGetCountries = createAsyncThunk(
	'analytics/getCountries',
	async (_, { rejectWithValue }) => {
		try {
			const response = await getCountryService(
				`fields=["_id","name"]&limit=250`
			);

			if (response.statusCode !== 200) {
				return rejectWithValue(response.message);
			} else if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}

			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const analyticsGetPropertySubscriptions = createAsyncThunk(
	'analytics/GetPropertySubscriptions',
	async ({ filter, page, limit }: any, { rejectWithValue }) => {
		try {
			const response = await getPropertySubscriptions(
				`page=${page}&limit=${limit}&filter={${Object.keys(filter)
					.filter((key) => filter[key])
					.map((key) => {
						if (key === 'subscriptionStatus')
							return `"${key}":["${filter[key]}"]`;
						if (key === 'appIds')
							return `"${key}": [${filter[key]}]`;
						if (key === 'partners')
							return `"${key}": [${filter[key]
								.map((partner: any) => `"${partner}"`)
								.join(',')}]`;
						if (key === 'propertySize')
							return `"${key}": ${filter[key]}`;
						return `"${key}":'${filter[key]}'`;
					})
					.join(',')}}`
			);
			if (response.statusCode !== 200) {
				return rejectWithValue(response.message);
			} else if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}
			return response;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const analyticsGetCjm = createAsyncThunk(
	'analytics/GetCjm',
	async (_, { rejectWithValue }) => {
		try {
			const response = await getCjm();

			if (response.statusCode !== 200) {
				return rejectWithValue(response.message);
			} else if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}
			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const analyticsGetDataProcessed = createAsyncThunk(
	'analytics/GetDataProcessed',
	async ({ apps, partners, name, size }: any, { rejectWithValue }) => {
		try {
			const response = await getDataProcessed(apps, partners, name, size);

			if (response.statusCode !== 200) {
				return rejectWithValue(response.message);
			} else if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}
			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);
export const analyticsGetSireTraData = createAsyncThunk(
	'analytics/GetSireTraData',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await getReportSireTraData(params);

			if (response.statusCode !== 200) {
				return rejectWithValue(response.message);
			} else if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}
			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const analyticsGetSiigoData = createAsyncThunk(
	'analytics/GetSiigoData',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await getReportSiigoData(params);

			if (response.statusCode !== 200) {
				return rejectWithValue(response.message);
			} else if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}
			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);
export const analyticsGetSatData = createAsyncThunk(
	'analytics/GetSatData',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await getReportSatData(params);

			if (response.statusCode !== 200) {
				return rejectWithValue(response.message);
			} else if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}
			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);
export const analyticsGetCheckinData = createAsyncThunk(
	'analytics/GetCheckinData',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await getReportCheckinData(params);

			if (response.statusCode !== 200) {
				return rejectWithValue(response.message);
			} else if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}
			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);
export const analyticsGetIMRData = createAsyncThunk(
	'analytics/GetIMRData',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await getReportIMRData(params);

			if (response.statusCode !== 200) {
				return rejectWithValue(response.message);
			} else if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}
			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);
export const analyticsGetPartners = createAsyncThunk(
	'analytics/getPartners',
	async (_, { rejectWithValue }) => {
		try {
			const response = await getPartners(
				`filter={"type": { "$in": ["pms", "ota"]}}&fields=["_id","name"]`
			);
			if (response.data) return response.data;

			if (response.statusCode !== 200) {
				return rejectWithValue(response.message);
			} else if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}

			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

export const analyticsGetResume = createAsyncThunk(
	'analytics/getResume',
	async (_, { rejectWithValue }) => {
		try {
			const response = await getResumeService();
			if (response.data) return response.data;

			if (response.statusCode !== 200) {
				return rejectWithValue(response.message);
			} else if (response.statusCode === 500) {
				return rejectWithValue(
					'Ocurrió un problema, inténtalo más tarde'
				);
			}

			return response.data;
		} catch (error: any) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);
