import { ApiURL } from '../util-url';

function jsonToUrlParams(jsonObject: Record<string, any>): string {
	let params: string[] = [];
	for (const key in jsonObject) {
		if (jsonObject.hasOwnProperty(key)) {
			params.push(`${key}=${jsonObject[key]}`);
		}
	}
	return params.join('&');
}

export interface getTemplateServiceProps {
	appId: string;
	propertyId: string;
	partnerIntegrationId?: string;
	partnerIntegrationIdTo?: string;
	filter?: string;
}
export const getTemplateService = (
	data: getTemplateServiceProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const urlParams = jsonToUrlParams(data);

		const url = ApiURL(`api/notifications/templates?${urlParams}`);

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const updateTemplateService = (id: string, data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const url = ApiURL(`api/notifications/templates/${id}`);

		const requestOptions = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface deleteTemplateServiceProps {
	appId: string;
	propertyId: string;
	partnerIntegrationId: string;
}
export const removeTemplateService = (
	id: string,
	props: deleteTemplateServiceProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const urlParams = jsonToUrlParams(props);

		const url = ApiURL(`api/notifications/templates/${id}?${urlParams}`);

		const requestOptions = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const postSendNotification = (
	type: 'whatsapp' | 'email',
	data: any,
	filter: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const url = ApiURL(
			`api/notifications/${type}/message-use-templates?filter=${filter}`
		);

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface getMessagesServiceProps {
	appId: string;
	propertyId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	filter: string;
	sort?: string;
}
export const getMessagesService = (
	type: 'whatsapp' | 'email',
	props: getMessagesServiceProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const urlParams = jsonToUrlParams(props);

		const url = ApiURL(`api/notifications/messages/${type}?${urlParams}`);

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const postGenerateTTLockService = (
	id: string,
	data: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const url = ApiURL(`api/third-party/ttlock/integration/add-lock/${id}`);

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const deleteTTLockService = (id: string, data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const url = ApiURL(`api/third-party/ttlock/integration/lock/${id}`);

		const requestOptions = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface smartLocksGenerateAccessCodeServiceProps {
	propertyId: string;
	partnerIntegrationId: string;
	partnerIntegrationIdTo: string;
	appId: string;
	data: any;
}
export const smartLocksGenerateAccessCodeService = ({
	propertyId,
	partnerIntegrationId,
	partnerIntegrationIdTo,
	appId,
	data
}: smartLocksGenerateAccessCodeServiceProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const url = ApiURL(
			`api/third-party/smartlock/bulk?propertyId=${propertyId}&partnerIntegrationId=${partnerIntegrationId}&partnerIntegrationIdTo=${partnerIntegrationIdTo}&appId=${appId}`
		);

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface smartLocksInitGenerateAccessCodeServiceProps {
	propertyId: string;
	appId: string;
	data: any;
}

export const smartLocksInitGenerateAccessCodeService = ({
	propertyId,
	appId,
	data
}: smartLocksInitGenerateAccessCodeServiceProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const url = ApiURL(
			`api/third-party/smartlock/smart-codes?propertyId=${propertyId}&appId=${appId}`
		);

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
