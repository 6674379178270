/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiURL } from '@hospy/util-api';

export interface IThirdPartyReservation {
	appId: string;
	propertyId: string;
	partnerIntegrationId: string;
	filter?: {
		status: string;
		startDate: string;
		endDate: string;
		search?: string;
	};
}
export interface IThirdPartyReservationFilters {
	propertyId: string;
	partnerIntegrationId: string;
	appId?: string;
	status?: string;
	search?: string;
}

export interface IReservationCheckInFilters
	extends IThirdPartyReservationFilters {
	checkInFrom?: string;
	checkInTo?: string;
}

export interface IReservationCheckOutFilters
	extends IThirdPartyReservationFilters {
	checkOutFrom?: string;
	checkOutTo?: string;
}

export interface ISiigoCreate {
	propertyIntegrationId: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	documentNumber: string;
	documentType: string;
	address: string;
	countryCode: string;
	city: string;
}

export interface IAlegraCreateCompany {
	name: string;
	nit: string;
	dv: string;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
}

export interface IAlegraCreateTestSet {
	type: string;
	governmentId: string;
	companyId: string;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
}

export interface AlegraGetTestSetParams {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
}

export const getThirdPartyReservations = (
	parameters: IReservationCheckInFilters | IReservationCheckOutFilters
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const url = ApiURL(
			`/api/third-party/reservations?filter=${JSON.stringify(parameters)}`
		);
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchReservationsSummaryCheckin = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const url = ApiURL(
			`/api/third-party/reservations/summary-checkin?filter=${JSON.stringify(
				data
			)}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const postRntReportsBulk = (
	type: 'rnt' | 'sire',
	data: any,
	parameters: IThirdPartyReservationFilters
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const url = ApiURL(
			`/api/third-party/${type}/reports/bulk?filter=${JSON.stringify(
				parameters
			)}`
		);
		const requestOptions = {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(url, requestOptions)
			.then(async (response: any) => {
				if (response.status == 200 || response.status == 204) {
					return { status: 'success', data: {} };
				}
				throw Error(await response.text());
			})
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
export const getReservationDetailService = (
	source: string,
	reservationId: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const url = ApiURL(
			`/api/third-party/reservations/${reservationId}?flags={"logs": true,"source":"${source}"}&sort={"createdAt": -1}&limit=3`
		);
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getReservationReportService = (
	reservationId: string,
	type: string,
	format?: 'contract' | 'summary'
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const url = ApiURL(
			`/api/pms/reservations/${reservationId}/reports/${type}${
				format ? '?type=' + format : ''
			}`
		);
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const cloudbedsActiveWebhookService = (
	propertyIntegrationId: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const url = ApiURL(`/api/third-party/cloudbeds/webhook/active`);

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				propertyIntegrationId
			})
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const cloudbedsIntegrationProcessService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(`/api/third-party/cloudbeds/integration`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const hubspotIntegrationProcessService = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(`/api/third-party/hubspot/integration`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getReservationSummaryService = (
	propertyId: string,
	date: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const url = ApiURL(
			`/api/third-party/reservations/summary?filter={"propertyId":"${propertyId}","date":"${date}"}`
		);
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const siigoValidateAuth = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(`/api/third-party/siigo/integration/validate-auth`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getReservation = (data: {
	reservationId: string;
	propertyIntegrationId: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(`/api/third-party/cloudbeds/reservations/find`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const siigoCreateClient = (data: ISiigoCreate): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(`/api/third-party/siigo/clients`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchCreatePointSaleAbT = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(
			`/api/third-party/facturante/onboarding/create-ab-and-t`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const fetchUpdatePointSaleAbT = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(
			`/api/third-party/facturante/onboarding/update-ab-or-t`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const alegraCreateCompany = (data: IAlegraCreateCompany): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(
			`/api/third-party/alegra/integration/create-company`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const alegraCreateTestSet = (data: IAlegraCreateTestSet): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};

		const url = ApiURL(
			`/api/third-party/alegra/integration/create-testset`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const alegraGetTestSet = (
	id: string,
	queryParams: AlegraGetTestSetParams
): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		};
		const params = Object.keys(queryParams)
			.map((clave) => {
				return (
					encodeURIComponent(clave) +
					'=' +
					encodeURIComponent(
						queryParams[clave as keyof AlegraGetTestSetParams]
					)
				);
			})
			.join('&');

		const url = ApiURL(
			`/api/third-party/alegra/integration/get-testset/${id}?${params}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const sendEmailReservationPdfService = (
	propertyId: string,
	email: string,
	subject: string,
	files: string[]
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({ propertyId, email, subject, files })
		};

		const url = ApiURL(`/api/third-party/reservations/send-pdf`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const sendLinkEmailReservationPdfService = (
	propertyId: string,
	emails: string[],
	subject: string,
	files: string[],
	language?: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				propertyId,
				emails,
				subject,
				files,
				language
			})
		};

		const url = ApiURL(`/api/third-party/reservations/send-link-pdf`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const removeAccessCodesService = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const url = ApiURL(
			`/api/third-party/reservations/remove-access-codes/${id}`
		);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
