/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Modal } from '@hospy/hospy-ui';
import { Spin, Typography, Upload, UploadFile, UploadProps } from 'antd';
import 'cropperjs/dist/cropper.css';
import { createRef, useCallback, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import styled from 'styled-components';
interface Props {
	aspect?: any;
	limit?: number;
	buttonUpload: React.ReactElement;
	onChange: (base64Files: any[]) => void;
	loading?: boolean;
}

export const toBase64 = (file: any) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

const UploadDocument = ({
	aspect,
	limit,
	buttonUpload,
	onChange,
	loading
}: Props) => {
	const [open, setOpen] = useState(false);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [aspectRatio] = useState(aspect || 3 / 2);
	const [limitFile] = useState<number>(limit || 1);
	const cropperRef = createRef<ReactCropperElement>();
	const [image, setImage] = useState<any>();
	const [isCropImage, setIsCropImage] = useState(true);

	const props: UploadProps = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: async (file) => {
			setOpen(true);
			const reader = new FileReader();
			reader.onload = () => {
				setImage(reader.result as any);
			};
			reader.readAsDataURL(file);
			return false;
		},
		fileList,
		accept: 'image/png, image/jpeg'
	};

	const handlerClose = () => {
		setOpen(false);
		setIsCropImage(true);
	};

	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const getCropData = useCallback(async () => {
		setLoadingSubmit(true);
		if (
			cropperRef.current &&
			cropperRef.current?.cropper &&
			typeof cropperRef.current?.cropper !== 'undefined'
		) {
			const cropper = cropperRef.current?.cropper?.getCroppedCanvas({
				maxWidth: 800
			});
			if (cropper) {
				await onChange([cropper.toDataURL('image/jpeg', 0.9)]);
			} else {
				setIsCropImage(false);
			}

			handlerClose();
			setFileList([]);
		}
		setLoadingSubmit(false);
	}, [image, cropperRef.current?.cropper]);

	return (
		<>
			<UploadStyled {...props}>
				{loading && (
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							zIndex: 2,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backdropFilter: 'blur(2px)',
							background: '#ffffff95'
						}}
					>
						<Spin />
					</div>
				)}
				{fileList.length <= limitFile && buttonUpload}
			</UploadStyled>
			<Modal
				open={open}
				onClose={handlerClose}
				padding="50px 0 0"
				destroyOnClose
			>
				{image && (
					<>
						<Cropper
							ref={cropperRef}
							style={{ height: 300, width: '100%' }}
							aspectRatio={aspectRatio}
							src={image}
							checkOrientation={false}
							background={false}
							responsive={true}
							zoomTo={0}
							viewMode={1}
							minCropBoxHeight={10}
							minCropBoxWidth={10}
							autoCropArea={1}
						/>
						{!isCropImage && (
							<Typography.Paragraph
								style={{
									padding: '15px 0px 0px 15px',
									color: 'red'
								}}
							>
								* Ocurrrio un error al delimitar la imagen
								intente de nuevo o cambie la imagen.
							</Typography.Paragraph>
						)}
						<div
							style={{
								padding: 15,
								display: 'flex',
								justifyContent: 'flex-end',
								gap: 15
							}}
						>
							<Button
								onClick={handlerClose}
								disabled={loadingSubmit}
							>
								Cancelar
							</Button>
							<Button
								type="primary"
								onClick={getCropData}
								loading={loadingSubmit}
								disabled={loadingSubmit}
							>
								Aceptar
							</Button>
						</div>
					</>
				)}
			</Modal>
		</>
	);
};

const UploadStyled = styled(Upload)`
	position: relative;
	.ant-upload.ant-upload-select {
		display: block;
	}
`;

export default UploadDocument;
